export const MainConstants = [
  { colorName: '--color-header-logo-text', color: '246 246 249' },
  { colorName: '--color-header-back-button', color: '181 183 193' },
  { colorName: '--color-header-icon', color: '161 163 173' },
  { colorName: '--color-header-text-button', color: '246 246 249' },
  { colorName: '--color-header-button-hover', color: '31 34 40' },
  { colorName: '--color-header-chevron', color: '161 163 173' },
  { colorName: '--color-header-background', color: '18 18 18' },
  { colorName: '--color-header-divider', color: '188 195 209' },
  { colorName: '--color-header-submenu-divider', color: '35 36 38' },
  {
    colorName: '--color-header-submenu-link-title',
    color: '246 246 249',
  },
  { colorName: '--color-header-submenu-content', color: '181 183 193' },
  { colorName: '--color-header-submenu-card-hover', color: '31 34 40' },
  { colorName: '--color-header-submenu-title', color: '182 183 188' },
];
