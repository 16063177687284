import { Breadcrumbs } from '@front/shared/ds';
import React, { ReactNode } from 'react';

import {
  FooterParser,
  HeaderParser,
  TMetaProps,
  Meta,
  Theme,
} from '../../../components';
import {
  useGetMetaQuery,
  useGetLocalesQuery,
  useGetPageQuery,
} from '../../../features';
import useActiveFooter from '../../../hooks/useActiveFooter';
import useActiveHeader from '../../../hooks/useActiveHeader';

import type { Page } from '@shared/master-types';

type TPageLayoutProps = {
  locale: string;
  draft?: boolean;
  headerDepth?: number;
  pageQueryDepth?: number;
  children: ReactNode;
  meta?: Partial<TMetaProps>;
  breadcrumbs?: Page['breadcrumbs'];
  pageId: string;
};

export const PageLayout: React.FC<TPageLayoutProps> = props => {
  const {
    draft,
    locale,
    children,
    meta: metaProps,
    breadcrumbs,
    pageId,
    pageQueryDepth,
    headerDepth,
  } = props;

  const { data: localesObject } = useGetLocalesQuery({
    locale,
    draft,
  });

  const { data: meta } = useGetMetaQuery({ locale });
  const { data: { navMenu, parts } = {} } = useGetPageQuery(
    { id: pageId, draft, locale, depth: pageQueryDepth },
    {
      skip: !pageId,
    },
  );

  const locales = localesObject?.docs;

  const footerObject = useActiveFooter(locale);
  const headerObject = useActiveHeader({
    locale,
    headerDepth: headerDepth,
  });

  const pageTheme = parts?.pageTheme;

  return (
    <>
      {!!pageTheme && <Theme pageTheme={pageTheme} />}

      {!!meta && <Meta {...meta} {...metaProps} />}

      {headerObject ? (
        <HeaderParser
          pageId={pageId}
          headers={headerObject}
          locales={locales}
          navMenu={navMenu}
        />
      ) : null}

      <main className='flex-1'>
        {!!breadcrumbs?.length && (
          <Breadcrumbs
            items={breadcrumbs}
            locale={locales?.length ? locale : ''}
          />
        )}
        {children}
      </main>

      {footerObject ? (
        <FooterParser footers={footerObject} locales={locales} />
      ) : null}
    </>
  );
};
