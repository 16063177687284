import { ImageProps } from 'next/image';
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  EStoryColor,
  EStorySize,
  iconCVA,
  storyCVA,
  titleCVA,
} from './Story.constants';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder';

export type TStoryProps = {
  icon?: ImageProps | null;
  title?: string;
  size?: EStorySize;
  color?: EStoryColor;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hasAnimation?: boolean;
  selected?: boolean;
  className?: string;
};

const Story: React.FC<TStoryProps> = props => {
  const {
    icon,
    title,
    size = EStorySize.SizeM,
    color = EStoryColor.Primary,
    selected: isSelected = false,
    hasAnimation = false,
    onClick,
    onMouseLeave,
    className,
    ...rest
  } = props;

  const [isAnimated, setIsAnimated] = useState<boolean>(false);
  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
  };

  const handleMouseLeave = (): void => {
    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  useEffect(() => {
    setIsAnimated(hasAnimation);
  }, [hasAnimation]);

  return (
    <div
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
      className={twMerge(
        storyCVA({ size, color, isAnimated, isSelected, className }),
      )}
      {...rest}
    >
      {icon && (
        <ImageWithPlaceholder
          className={iconCVA({ size, color, isSelected })}
          {...icon}
        />
      )}

      {title && (
        <span title={title} className={titleCVA({ size, color })}>
          {title}
        </span>
      )}
    </div>
  );
};

export default Story;
