import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { TTableOfContentsAccum } from './utils';

export interface ITableOfContentsProps {
  tableOfContentsStructure: TTableOfContentsAccum;
  title?: string;
}

const TableOfContents: React.FC<ITableOfContentsProps> = props => {
  const { tableOfContentsStructure, title } = props;

  const router = useRouter();
  const [activeItemId, setActiveItemId] = useState<string>('');

  useEffect(() => {
    setActiveItemId(
      router.asPath.indexOf('#') >= 0 ? router.asPath.split('#')[1] : '',
    );
  }, [router]);

  const scrollBarClasses =
    'scrollbar scrollbar-thin scrollbar-w-1 scrollbar-thin scrollbar-thumb-surface-200 scrollbar-track-surface-50 scroll-smooth lg:overflow-auto';

  const createTableOfContents = (
    items: TTableOfContentsAccum,
  ): React.JSX.Element => {
    return (
      <div className='flex flex-col'>
        {Object.values(items).map((item, index) => {
          const itemAnchorLink = `#${item.id}`;
          const subItemsAnchorLinks = item.subItems.map(subItem => subItem.id);

          const isItemActive =
            (activeItemId && itemAnchorLink.indexOf(activeItemId) >= 0) ||
            subItemsAnchorLinks.includes(activeItemId);

          return (
            <React.Fragment key={`${index}-${item.text}`}>
              <li className='width-full'>
                <Link
                  className={clsx(
                    'width-full block cursor-pointer rounded-lg px-3 py-2 text-sm font-normal leading-loose transition-all duration-500 hover:bg-control-100',
                    {
                      'bg-interface-800/10 text-brand-500': isItemActive,
                      'text-interface-800': !isItemActive,
                    },
                  )}
                  href={itemAnchorLink}
                  scroll={false}
                >
                  {item.text}
                </Link>
              </li>
              {item.subItems.length !== 0 && (
                <ul
                  className={clsx(
                    'width-full flex flex-col overflow-y-hidden ps-2 transition-all duration-500',
                    {
                      'max-h-[28rem] py-2': isItemActive,
                      'max-h-0 py-0': !isItemActive,
                    },
                  )}
                >
                  {item.subItems.map((subItem, subIndex) => {
                    const subItemAnchorLink = `#${subItem.id}`;
                    const isSubItemActive =
                      activeItemId &&
                      subItemAnchorLink.indexOf(activeItemId) >= 0;

                    return (
                      <li
                        key={`${subIndex}-${subItem.text}`}
                        className='width-full'
                      >
                        <Link
                          className={clsx(
                            'width-full block cursor-pointer rounded-lg px-3 py-2 text-sm text-interface-800 transition-all duration-500 hover:bg-control-100',
                            {
                              'bg-control-100': isSubItemActive,
                            },
                          )}
                          href={subItemAnchorLink}
                          scroll={false}
                        >
                          {subItem.text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <div className={clsx('flex flex-col gap-3')}>
      {title && (
        <h2 className='hidden text-xl font-semibold leading-normal text-interface-1000 lg:block'>
          {title}
        </h2>
      )}
      <div className={clsx('lg:max-h-[70vh]', scrollBarClasses)}>
        <ul className='flex max-h-[21.25rem] flex-col gap-2 overflow-auto lg:max-h-full lg:pr-2'>
          {createTableOfContents(tableOfContentsStructure)}
        </ul>
      </div>
    </div>
  );
};

export default TableOfContents;
