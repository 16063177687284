import { Document } from '@shared/master-types';

import { baseApi } from '../../store/store.constants';

export const documentsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getDocument: build.query<
      Document,
      { id: string; locale: string; draft?: boolean }
    >({
      query: ({ id, ...params }) => ({
        url: `/documents/${id}`,
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getDocument },
  useGetDocumentQuery,
} = documentsApi;
