import { CompanyRanking, Locale } from '@shared/master-types';

export type TCompanyRankingExtended = CompanyRanking & {
  currentLocale: string;
  locales?: Locale[];
  slug?: string;
};

export default function buildCompanyRankingsList(
  docsList: (string | TCompanyRankingExtended)[] | CompanyRanking[] | undefined,
  currentLocale: string,
  locales?: Locale[],
): Array<TCompanyRankingExtended> {
  if (!docsList) {
    console.warn('%c* WARNING *', 'color: orange; font-weight: bold');
    console.warn(
      '%cNo companyRankingList provided, check CompanyRankings block in your page',
      'color: red; font-style: italic',
    );
    return [];
  }

  const processedArticles: Array<TCompanyRankingExtended> = [];

  for (const doc of docsList) {
    if (typeof doc === 'string') {
      console.warn('%c* WARNING *', 'color: orange; font-weight: bold');
      console.warn(
        '%cWrong companyRankingList provided, check CompanyRankings block in your page',
        'color: red; font-style: italic',
      );
      continue;
    }

    const {
      id,
      title,
      url,
      createdAt,
      typeOfBusiness,
      readingTime,
      parent,
      updatedAt,
      banner,
      location,
      logo,
      excerpt,
      accordion,
    } = doc;

    processedArticles.push({
      id,
      title,
      url,
      createdAt,
      typeOfBusiness,
      readingTime,
      banner,
      location,
      logo,
      excerpt,
      parent,
      updatedAt,
      currentLocale,
      locales,
      accordion,
    });
  }

  return processedArticles;
}
