import { BlogAuthor } from '@shared/master-types';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { PaginatedDocs } from 'payload/dist/mongoose/types';
import { Where } from 'payload/types';

import { baseApi } from '../../store/store.constants';

type TParams = {
  locale?: string;
  depth?: number;
  draft?: boolean;
  page?: number;
  limit?: number;
  where?: Where;
};

export const blogPostsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBlogAuthors: build.query<PaginatedDocs<BlogAuthor>, TParams | void>({
      query: params => ({
        url: '/blog-authors?depth=0',
        params: params ?? undefined,
      }),
    }),
  }),
});

export const {
  endpoints: { getBlogAuthors },
  useGetBlogAuthorsQuery,
} = blogPostsApi;
