import { addJurisdictionSupportItem } from '@front/shared/utils';
import {
  BlogArticle,
  BlogTag,
  Jurisdiction,
  Locale,
  User,
} from '@shared/master-types';
import clsx from 'clsx';
import Link from 'next/link';
import React, { ReactNode } from 'react';

import { EActionType, TAction } from '../../../types';
import {
  buildTagCategoryLink,
  toImageProps,
  toLinkProps,
} from '../../../utils';
import { ArticleThumbnail } from '../../atoms/ArticleThumbnail';
import { Container } from '../../sections/Container';

export interface IBlogArticleExtended extends BlogArticle {
  currentLocale: string;
  jurisdictionList?: Jurisdiction[];
  locales?: Locale[];
  slug?: string;
}

export interface INewsBlockProps {
  tagId: string;
  tagMenu?: {
    link?: TAction;
    tagId: string;
  }[];
  newsList: IBlogArticleExtended[];
  pagination: ReactNode;
  emptyText?: string;
  isLoading: boolean;
}

const NewsBlock: React.FC<INewsBlockProps> = ({
  tagId,
  tagMenu,
  newsList,
  pagination,
  emptyText,
  isLoading,
}) => {
  const linkClasses =
    'flex h-full snap-center items-center rounded-xl px-6 py-3 text-base font-medium leading-relaxed transition lg:hover:bg-brand-500 lg:hover:text-white';

  return (
    <Container>
      <div className='scrollbar-hidden min-w-[calc(100% + 2rem)] mx-auto mb-8 ml-[-1rem] mr-[-1rem] flex snap-x snap-mandatory justify-start gap-3 overflow-x-auto px-4 pe-4 ps-4 lg:ml-auto lg:mr-auto lg:min-w-full lg:justify-center  2xl:mb-12'>
        {tagMenu?.map(menuItem => (
          <li key={menuItem.tagId} className='list-none whitespace-nowrap'>
            {menuItem?.link?.type === 'link' && (
              <Link
                className={clsx(linkClasses, {
                  'bg-brand-500 text-white': tagId === menuItem.tagId,
                  'bg-control-100 text-control-950': tagId !== menuItem.tagId,
                })}
                {...menuItem.link.props}
              >
                {menuItem.link.props.children}
              </Link>
            )}
          </li>
        ))}
      </div>
      {newsList?.length ? (
        <section className='grid gap-10 lg:grid-cols-2 2xl:grid-cols-4 2xl:gap-x-4 2xl:gap-y-12'>
          {newsList?.map(currentArticle => {
            const {
              id,
              cover,
              title,
              url: defaultUrl,
              createdAt,
              publishedAt,
              tags,
              authors,
              currentLocale,
              jurisdictionList,
              locales,
              slug,
            } = currentArticle;

            const currentArticleTag = (
              tags as unknown as {
                value: BlogTag;
              }[]
            ).map(tag => {
              return {
                id: tag.value.id,
                children: tag.value.name,
                href: buildTagCategoryLink(currentLocale, slug, tag.value.slug),
              };
            });

            const currentArticleAuthor = (authors as unknown as User[]).map(
              author => ({
                name: `${author.firstName} ${author.lastName}`,
              }),
            );

            const preparedTActions: TAction = {
              type: EActionType.LinkType,
              props: {
                ...toLinkProps(currentLocale, {
                  text: title,
                  url: defaultUrl || '',
                  linkType: 'internal',
                  doc: { value: '', relationTo: 'blog-articles' },
                  rel: [],
                }),
              },
            };
            const url = addJurisdictionSupportItem({
              jurisdictionList,
              localeList: locales,
              slug,
              item: preparedTActions,
            });

            return (
              <ArticleThumbnail
                key={id}
                cover={toImageProps(cover)}
                title={title}
                url={url}
                publishedAt={publishedAt || createdAt}
                tags={currentArticleTag}
                authors={currentArticleAuthor}
              />
            );
          })}
        </section>
      ) : (
        !isLoading &&
        emptyText && (
          <p className='mx-auto w-full max-w-[39.5rem] rounded-xl bg-surface-100 p-8 text-center text-lg font-normal text-interface-1000'>
            {emptyText}
          </p>
        )
      )}
      {pagination}
    </Container>
  );
};

export default NewsBlock;
