export default function buildDateFormat(
  date: Date,
  currentLocale: string,
): string {
  return date.toLocaleDateString(currentLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}
