import { toImageProps } from '@front/shared/ds';
import { Media } from '@shared/master-types';
import Image from 'next/image';
import React from 'react';

import { VideoResponsive } from '../../common';

export type THeroVideoProps = {
  video: Media | string;
  videoPoster?: Media | string;
  videoOverlay?: Media | string;
};

const HeroVideo: React.FC<THeroVideoProps> = ({
  video,
  videoPoster,
  videoOverlay,
}) => {
  const fallbackImage = toImageProps(videoPoster);
  const overlayImage = toImageProps(videoOverlay);

  if (typeof video === 'string') return null;

  return (
    <div className='relative mt-12 rounded-[1.8rem] sm:px-8 md:px-12 xl:px-28'>
      <div className='py-5'>
        {fallbackImage && (
          <div className='absolute left-0 -z-10 w-full rounded-[1.8rem] sm:px-8 md:px-12 xl:px-28'>
            <Image
              {...fallbackImage}
              className='rounded-[1.8rem]'
              alt={fallbackImage.alt}
            />
          </div>
        )}
        <div className='min-h-[50vw]  lg:min-h-[45vw] xl:min-h-[42vw] 2xl:min-h-[35vw]'>
          <VideoResponsive
            className='rounded-[1.8rem]'
            loop
            playsInline
            muted
            autoPlay
            video={video}
          />
        </div>
      </div>

      {overlayImage && (
        <Image
          {...overlayImage}
          className='absolute bottom-0 left-0 w-full px-0 sm:bottom-2 sm:px-1 md:bottom-1 md:px-1 lg:bottom-0 lg:px-0 xl:-bottom-2 xl:px-12 2xl:-bottom-3 2xl:top-[-0.375rem] 2xl:px-10'
          alt={overlayImage.alt}
          priority
        />
      )}
    </div>
  );
};
export default HeroVideo;
