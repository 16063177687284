export const publicRuntimeConfig = {
  NEXT_PUBLIC_SENTRY_DSN_URL: process.env.NEXT_PUBLIC_SENTRY_DSN_URL
    ? process.env.NEXT_PUBLIC_SENTRY_DSN_URL.toString()
    : '',
  NEXT_APP_HOST: process.env.NEXT_APP_HOST
    ? process.env.NEXT_APP_HOST.toString()
    : '',
  CDN_BASE_URL: process.env.CDN_BASE_URL
    ? process.env.CDN_BASE_URL.toString()
    : '',
  NEXT_IMAGE_CROP_ENABLED: process.env.NEXT_IMAGE_CROP_ENABLED
    ? process.env.NEXT_IMAGE_CROP_ENABLED.toString()
    : '',
  NEXT_VIDEO_CROP_ENABLED: process.env.NEXT_VIDEO_CROP_ENABLED
    ? process.env.NEXT_VIDEO_CROP_ENABLED.toString()
    : '',
};
