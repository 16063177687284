import { getGaId } from '@front/shared';
import clsx from 'clsx';
import React from 'react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useFixedActionVisibility } from '../../../../../utils/useFixedActionVisibility';
import { ReactComponent as IconFixedElement } from '../../../../public/icons/components/fixedElement/call-icon.svg';
import { TAction } from '../../../types/actions';
import { Button, EButtonSize, EButtonTheme } from '../Button';

export type TFixedElementProps = {
  action: TAction;
};

export const FixedElement: React.FC<TFixedElementProps> = props => {
  const { action } = props;
  const isFixedActionVisible = useFixedActionVisibility();

  return (
    <div
      className={clsx(
        'fixed bottom-4 left-1/2 w-full max-w-[80.5rem] -translate-x-1/2 px-4 transition-opacity',
        isFixedActionVisible
          ? 'opacity-1 pointer-events-auto z-10'
          : 'pointer-events-none -z-10 opacity-0',
      )}
    >
      <div className='relative top-auto mx-auto w-full max-w-fit cursor-pointer gap-2 rounded-xl bg-brand-1000/80 transition ease-in-out hover:bg-brand-1000 lg:ml-auto lg:mr-0'>
        <IconFixedElement className='absolute inset-3 top-1/2 z-10 h-4 w-4 -translate-y-1/2 transform' />
        {action.type === 'button' && (
          <Button
            className={
              'relative z-20 !py-[0.594rem] !pl-[2.5rem] !pr-4 text-brand-50/90'
            }
            {...action.props}
            theme={EButtonTheme.Tertiary}
            size={EButtonSize.Small}
            data-ga-id={getGaId(action.dataGaId || action.text, 'floating')}
          />
        )}
      </div>
    </div>
  );
};
