import { cx, cva } from 'cva';
import Link, { LinkProps } from 'next/link';
import React, { ReactNode } from 'react';

import { ETagSize, ETagTheme, ETagVariant } from './Tag.constants';

export const tagCVA = cva(
  'font-medium transition flex items-center leading-relaxed rounded rounded-lg',
  {
    variants: {
      variant: {
        [ETagVariant.Contained]: '',
        [ETagVariant.Outlined]: '',
        [ETagVariant.Text]: '',
      },
      size: {
        [ETagSize.Large]: '',
        [ETagSize.Medium]: '',
        [ETagSize.Small]: 'text-xs',
      },
      theme: {
        [ETagTheme.Primary]: '',
        [ETagTheme.Secondary]: '',
        [ETagTheme.PrimaryOnColour]: '',
        [ETagTheme.SecondaryOnColour]: '',
      },
      href: {
        true: '',
      },
      disabled: {
        true: 'cursor-not-allowed opacity-40',
      },
    },
    compoundVariants: [
      {
        size: ETagSize.Large,
        variant: [ETagVariant.Contained, ETagVariant.Outlined],
        className: 'h-8',
      },
      {
        size: ETagSize.Medium,
        variant: [ETagVariant.Contained, ETagVariant.Outlined],
        className: 'h-7 px-3',
      },
      {
        size: ETagSize.Small,
        variant: [ETagVariant.Contained, ETagVariant.Outlined],
        className: 'py-1 px-3',
      },
      {
        theme: ETagTheme.Primary,
        variant: ETagVariant.Text,
        className: 'text-brand-500 hover:text-brand-550 focus:text-brand-550',
      },
      {
        theme: ETagTheme.Secondary,
        variant: ETagVariant.Text,
        className:
          'text-control-600 hover:text-control-650 focus:text-control-650',
      },
      {
        theme: ETagTheme.PrimaryOnColour,
        variant: ETagVariant.Text,
        className:
          'text-brand-1000 hover:text-brand-1000/80 focus:text-brand-1000/80',
      },
      {
        theme: ETagTheme.SecondaryOnColour,
        variant: ETagVariant.Text,
        className: 'text-brand-50/90 hover:text-brand-50 focus:text-brand-50',
      },
      {
        theme: ETagTheme.Primary,
        variant: ETagVariant.Contained,
        className:
          'text-brand-50/90 bg-brand-500 hover:text-brand-50 hover:bg-brand-550 focus:text-brand-50 focus:bg-brand-550',
      },
      {
        theme: ETagTheme.Secondary,
        variant: ETagVariant.Contained,
        className:
          'text-brand-500 bg-brand-250 hover:text-brand-550 hover:bg-brand-300',
      },
      {
        theme: ETagTheme.PrimaryOnColour,
        variant: ETagVariant.Contained,
        className:
          'text-brand-50/90 bg-brand-1000/80 hover:text-brand-50 hover:bg-brand-1000 focus:text-brand-50 focus:bg-brand-1000',
      },
      {
        theme: ETagTheme.SecondaryOnColour,
        variant: ETagVariant.Contained,
        className:
          'text-brand-1000/80 bg-brand-50/90 hover:text-brand-1000 hover:brand-50 focus:text-brand-1000 focus:brand-50',
      },
      {
        theme: ETagTheme.Primary,
        variant: ETagVariant.Outlined,
        className:
          'text-brand-500 border border-brand-500 hover:text-brand-50 hover:bg-brand-500 focus:text-brand-50 focus:bg-brand-500',
      },
      {
        theme: ETagTheme.Secondary,
        variant: ETagVariant.Outlined,
        className:
          'text-control-600 border hover:text-brand-50 hover:bg-control-650 focus:text-brand-50 focus:bg-control-650',
      },
      {
        theme: ETagTheme.PrimaryOnColour,
        variant: ETagVariant.Outlined,
        className:
          'text-brand-1000 border border-brand-1000 hover:text-brand-50 hover:bg-brand-1000/80 focus:text-brand-50 focus:bg-brand-1000/80',
      },
      {
        theme: ETagTheme.SecondaryOnColour,
        variant: ETagVariant.Outlined,
        className:
          'text-brand-50/90 border border-brand-50/90 hover:text-brand-1000 hover:bg-brand-50 focus:text-brand-1000 focus:bg-brand-50',
      },
    ],
    defaultVariants: {
      size: ETagSize.Medium,
      theme: ETagTheme.Primary,
    },
  },
);

export type TTagProps = Omit<
  {
    children?: ReactNode;
    variant?: ETagVariant;
    size?: ETagSize;
    theme?: ETagTheme;
    disabled?: boolean;
  } & Partial<LinkProps>,
  'classname'
> &
  Omit<React.ComponentProps<'span'>, 'ref'>;

const Tag: React.FC<TTagProps> = props => {
  const {
    variant = ETagVariant.Contained,
    size = ETagSize.Large,
    theme = ETagTheme.Primary,
    disabled,
    href,
    children,
    ...restProps
  } = props;

  return href ? (
    <Link
      {...restProps}
      href={href}
      className={cx(tagCVA({ variant, disabled, size, theme, href: !!href }))}
    >
      {children}
    </Link>
  ) : (
    <span
      {...restProps}
      className={cx(tagCVA({ variant, disabled, size, theme, href: !!href }))}
    >
      {children}
    </span>
  );
};

export default Tag;
