import React from 'react';

export const HeroVariantVideoV2Background: React.FC = () => {
  return (
    <div className='absolute top-0 -z-10 grid h-[33rem] w-full bg-brand-100 bg-surface-1000 p-4 lg:rounded-[2rem] xl:h-[44rem] xl:p-8'>
      <div className='bottom-0 left-[10%] mx-auto h-[75%] w-[80%] blur-xl'>
        <div className='h-full w-full bg-gradient-to-b from-transparent to-brand-500 [clip-path:polygon(0%_0%,100%_0%,70%_100%,30%_100%)]' />
      </div>
    </div>
  );
};

export default HeroVariantVideoV2Background;
