import Image, { ImageProps } from 'next/image';
import React from 'react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import imgPreloader from '../../../../../utils/ImgPreloader/imgPreloader';

export type TImageWithPlaceholderProps = ImageProps & {
  isDark?: boolean;
};

export const ImageWithPlaceholder: React.FC<TImageWithPlaceholderProps> = ({
  isDark = false,
  ...props
}) => {
  return (
    <Image
      placeholder={`data:image/${imgPreloader(props.width, props.height, isDark)}`}
      {...props}
    />
  );
};
