import { Media } from '@shared/master-types';
import clsx from 'clsx';
import React from 'react';

import toImageProps from '../../../utils/toImageProps';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import { RichText, TRichTextContent } from '../RichText';

export type TImageBlockProps = {
  image: string | Media | undefined;
  text?: TRichTextContent;
  isOpenInNewTab?: boolean;
};

export const ImageBlock: React.FC<TImageBlockProps> = props => {
  const { image, text, isOpenInNewTab } = props;

  const imageWithProps = toImageProps(image);

  return (
    <div className={'py-4'}>
      {imageWithProps && (
        <ImageWithPlaceholder
          {...imageWithProps}
          itemProp='image'
          className='w-full rounded-3xl'
        />
      )}
      <div
        className={clsx(
          'break-words pt-2',
          'prose-a:text-brand-500 prose-a:transition hover:prose-a:text-brand-550',
          'prose-p:text-xs prose-p:leading-loose prose-p:text-interface-500',
        )}
      >
        <RichText isOpenInNewTab={isOpenInNewTab} content={text} />
      </div>
    </div>
  );
};
