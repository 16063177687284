import { useCallback } from 'react';

import { setCurrentModalId } from './modals.state';
import { useAppDispatch, useAppSelector } from '../../store/store.hooks';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useModalsControl = () => {
  const dispatch = useAppDispatch();

  const currentModalId = useAppSelector(state => state.modals.currentModalId);

  const closeModal = useCallback(
    () => dispatch(setCurrentModalId({ modalId: null })),
    [dispatch],
  );

  const openModal = useCallback<
    (args: Parameters<typeof setCurrentModalId>[0]) => void
  >(({ modalId }) => dispatch(setCurrentModalId({ modalId })), [dispatch]);

  return {
    currentModalId,
    closeModal,
    openModal,
  };
};
