import { Media } from '@shared/master-types';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import { Text } from 'slate';

import { LinkParser } from './LinkParser';
import MultiLineText from './MultiLineText';
import RelationshipField from './RelationshipField';
import {
  ERichTextDefaultTag,
  ERichTextBullets,
  ERichTextBulletsTheme,
} from './constants';
import { TChildren } from './types';
import { skipEmptyNodesWithLink } from './utils/skipEmptyNodesWithLink';
import buildHashId from '../../../utils/buildHashId';
import buildStringFromRichTextRow from '../../../utils/buildStringFromRichTextRow';
import toImageProps from '../../../utils/toImageProps';
import toLinkParam from '../../../utils/toLinkParam';
import { VideoPlayer } from '../VideoPlayer/index';

const Serialize = (
  children: TChildren = [],
  options: {
    bullets?: `${ERichTextBullets}`;
    bulletsTheme?: `${ERichTextBulletsTheme}`;
    onOpenModal?: (modalId: string) => void;
    defaultTag?: ERichTextDefaultTag;
  },
): (React.JSX.Element | null)[] => {
  const hideClass = 'invisible relative top-[-6rem] -z-10';

  const router = useRouter();
  const linkParam = toLinkParam({
    locale: router.query.locale?.toString(),
    jurisdiction: router.query.jurisdiction?.toString(),
  });

  const preparedOptions = {
    bullets: ERichTextBullets.CircleMark,
    bulletsTheme: ERichTextBulletsTheme.Theme1,
    defaultTag: ERichTextDefaultTag.P,
    ...options,
  };

  return children?.map((node, i) => {
    if (Text.isText(node)) {
      let text = <MultiLineText key={i} rowText={node.text} />;

      if (!!i && !node.text) {
        text = <br />;
      }

      if (node.bold) {
        text = <strong key={i}>{text}</strong>;
      }

      if (node.code) {
        text = <code key={i}>{text}</code>;
      }

      if (node.italic) {
        text = <em key={i}>{text}</em>;
      }

      if (node.underline) {
        text = (
          <span style={{ textDecoration: 'underline' }} key={i}>
            {text}
          </span>
        );
      }

      if (node.strikethrough) {
        text = (
          <span style={{ textDecoration: 'line-through' }} key={i}>
            {text}
          </span>
        );
      }

      return <Fragment key={i}>{text}</Fragment>;
    }

    if (!node) {
      return null;
    }

    const preparedString = buildStringFromRichTextRow(node.children);
    const nodeId = node.id || buildHashId(preparedString);

    switch (node.type) {
      case 'h1':
        return (
          <h1 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children, preparedOptions)}
          </h1>
        );
      case 'h2':
        return (
          <h2 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children, preparedOptions)}
          </h2>
        );
      case 'h3':
        return (
          <h3 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children, preparedOptions)}
          </h3>
        );
      case 'h4':
        return (
          <h4 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children, preparedOptions)}
          </h4>
        );
      case 'h5':
        return (
          <h5 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children, preparedOptions)}
          </h5>
        );
      case 'h6':
        return (
          <h6 key={i}>
            <span id={nodeId} className={clsx(hideClass)} />
            {Serialize(node.children, preparedOptions)}
          </h6>
        );
      case 'ul':
        return (
          <ul className='ps-[1.5rem]' key={i}>
            {Serialize(node.children, preparedOptions)}
          </ul>
        );
      case 'ol':
        // Because Tailwind library automatically added content property to any :before css, we hide it here
        return (
          <ol className='[&>li]:before:content-none' key={i}>
            {Serialize(node.children, {
              ...preparedOptions,
              bullets: ERichTextBullets.Decimal,
            })}
          </ol>
        );
      case 'li':
        return (
          <li
            key={i}
            className={clsx(
              'custom-li relative before:absolute before:-start-[1.35rem] before:top-[0.38rem] before:h-4 before:w-4 prose-p:!py-0',
              {
                'icon-circle':
                  preparedOptions.bullets === ERichTextBullets.CircleMark,
                'icon-checkmark':
                  preparedOptions.bullets === ERichTextBullets.CheckMark,
                'icon-rocket':
                  preparedOptions.bullets === ERichTextBullets.Rocket,
                'before:bg-brand-500':
                  preparedOptions.bulletsTheme === ERichTextBulletsTheme.Theme1,
                'before:bg-interface-400':
                  preparedOptions.bulletsTheme === ERichTextBulletsTheme.Theme2,
                'list-decimal ps-1':
                  preparedOptions.bullets === ERichTextBullets.Decimal,
              },
            )}
          >
            {Serialize(
              skipEmptyNodesWithLink(node.children, true),
              preparedOptions,
            )}
          </li>
        );
      case 'color':
        return node.color || node.backgroundImage ? (
          <span
            key={i}
            style={{
              color: node.backgroundImage ? 'transparent' : String(node.color),
              ...(node.backgroundImage
                ? {
                    backgroundImage: String(node.backgroundImage),
                    backgroundClip: 'text',
                  }
                : {}),
            }}
          >
            {Serialize(node.children, preparedOptions)}
          </span>
        ) : (
          <Fragment key={i}>
            {Serialize(node.children, preparedOptions)}
          </Fragment>
        );
      case 'link':
        return (
          <LinkParser
            key={i}
            onOpenModal={options.onOpenModal}
            linkParam={linkParam}
            node={node}
          >
            {Serialize(node.children, preparedOptions)}
          </LinkParser>
        );

      case 'relationship':
        return (
          <RelationshipField
            options={preparedOptions}
            key={i}
            node={node}
            index={i}
          />
        );
      case 'upload':
        return node.value?.url ? (
          <Image
            className='!static'
            key={i}
            src=''
            alt=''
            {...toImageProps(node.value as Media, { useFill: true })}
          />
        ) : null;
      case 'video':
        return node.id ? (
          <div key={i}>
            <VideoPlayer embedId={node.id as string} />
          </div>
        ) : null;

      default:
        return preparedOptions.defaultTag === ERichTextDefaultTag.P ? (
          <p key={i}>
            {Serialize(skipEmptyNodesWithLink(node.children), preparedOptions)}
          </p>
        ) : (
          <span key={i}>
            {Serialize(skipEmptyNodesWithLink(node.children), preparedOptions)}
          </span>
        );
    }
  });
};

export default Serialize;
