import { Pathname } from '@shared/master-types';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { PaginatedDocs } from 'payload/dist/mongoose/types';
import { Where } from 'payload/types';

import { baseApi } from '../../store/store.constants';

export const sitemapApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getSitemap: build.query<
      PaginatedDocs<Pathname>,
      {
        depth?: number;
        locale?: string;
        page?: number;
        limit?: number;
        where?: Where;
      }
    >({
      query: params => ({
        url: '/pathnames',
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getSitemap },
  useGetSitemapQuery,
} = sitemapApi;
