import {
  Header,
  Jurisdiction,
  Locale,
  NavMenu as INavMenu,
} from '@shared/master-types';
import dynamic from 'next/dynamic';
import React from 'react';

const HeaderConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "HeaderConnected" */ '../../layout/HeaderConnected'
    ),
);

const HeaderMegaMenuConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "HeaderMegaMenuConnected" */ '../../layout/HeaderMegaMenuConnected'
    ),
);

const NavMenuConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "NavMenuConnected" */ '../../layout/NavMenuConnected'
    ),
);

export interface IHeaderParserProps {
  headers?: Header | string;
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
  navMenu?: INavMenu | string;
  pageId: string;
}

const HeaderParser: React.FC<IHeaderParserProps> = props => {
  const {
    headers: headerDocs,
    locales,
    jurisdictionList,
    jurisdictionItem,
    navMenu,
    pageId,
  } = props;

  if (typeof headerDocs !== 'string') {
    const header = headerDocs?.header && headerDocs?.header[0];

    switch (header?.blockType) {
      case 'header-simple':
        return (
          <HeaderConnected
            {...header}
            locales={locales}
            jurisdictionList={jurisdictionList}
            jurisdictionItem={jurisdictionItem}
          />
        );
      case 'header-mega-menu':
        return (
          <>
            <HeaderMegaMenuConnected
              {...header}
              locales={locales}
              pageId={pageId}
              withNavMenu={!!navMenu}
              navMenuLogo={(navMenu as INavMenu)?.logoIcon}
              navMenuLogoTitle={(navMenu as INavMenu)?.title}
            />
            {navMenu && <NavMenuConnected {...(navMenu as INavMenu)} />}
          </>
        );
      default:
        return null;
    }
  }
  return null;
};

export default HeaderParser;
