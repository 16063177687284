import { useEffect } from 'react';

import { useLocale } from '../../../../hooks';
import { getLanguageDirection } from '../../../../utils/getLanguageDirection';

export const useApplyLocaleOnDocument = (): ReturnType<typeof useLocale> => {
  const { locale, isLocaleLoading } = useLocale();

  useEffect(() => {
    if (locale) {
      document.documentElement.lang = locale;
      document.documentElement.dir = getLanguageDirection(locale);
    }
  }, [locale]);

  return {
    locale,
    isLocaleLoading,
  };
};
