import { Document } from '@shared/master-types';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { PaginatedDocs } from 'payload/dist/mongoose/types';
import { Where } from 'payload/types';

import { baseApi } from '../../store/store.constants';

export const documentsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getDocuments: build.query<
      PaginatedDocs<Document>,
      {
        locale?: string;
        draft?: boolean;
        where?: Where;
        page?: number;
        limit?: number;
      }
    >({
      query: params => ({
        url: '/documents',
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getDocuments },
  useGetDocumentsQuery,
} = documentsApi;
