import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { GetStaticPropsContext } from 'next';
import { createWrapper, Context } from 'next-redux-wrapper';

import { baseApi, listenerMiddleware } from './store.constants';
import { TAppStore } from './store.types';
import modals from '../features/modals/modals.state';

export const reducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  modals,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createStore = (context: Context) => {
  return configureStore({
    reducer,
    devTools: true,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            cookies: (
              context as GetStaticPropsContext<
                Record<string, string>,
                { cookies: Record<string, string> }
              >
            )?.previewData?.cookies,
          },
        },
      }).prepend(listenerMiddleware.middleware, baseApi.middleware),
  });
};

const wrapper = createWrapper<TAppStore>(createStore, { debug: false });

export default wrapper;
