import { BlogEvent, TPayloadCollectionQueryParams } from '@shared/master-types';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { PaginatedDocs } from 'payload/dist/mongoose/types';

import { baseApi } from '../../store/store.constants';

export const blogPostsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBlogEvents: build.query<
      PaginatedDocs<BlogEvent>,
      TPayloadCollectionQueryParams | void
    >({
      query: params => ({
        url: '/blog-events?depth=1',
        params: params ?? undefined,
      }),
    }),
  }),
});

export const {
  endpoints: { getBlogEvents },
  useGetBlogEventsQuery,
} = blogPostsApi;
