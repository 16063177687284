import React from 'react';

export const Mask: React.FC = () => {
  return (
    <div
      className='absolute left-0 top-0 h-full w-full backdrop-blur-sm'
      style={{
        background:
          'linear-gradient(90deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.00) 100%)',
      }}
    >
      <div
        className='h-[108px]'
        style={{
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 1.9%, rgba(0, 0, 0, 0.01) 9.05%, rgba(0, 0, 0, 0.05) 16.9%, rgba(0, 0, 0, 0.72) 100%)',
        }}
      />
    </div>
  );
};
