import { Breadcrumbs } from '@front/shared/ds';
import React, { ReactNode } from 'react';

import {
  FooterParser,
  HeaderParser,
  TMetaProps,
  Meta,
  SidebarConnected,
} from '../../../components';
import {
  useGetFooterQuery,
  useGetHeaderQuery,
  useGetMetaQuery,
  useGetLocalesQuery,
} from '../../../features';

import type { Page, Sidebar } from '@shared/master-types';

type TNewsWebsitePageLayoutProps = {
  locale: string;
  draft?: boolean;
  children: ReactNode;
  meta?: Partial<TMetaProps>;
  breadcrumbs?: Page['breadcrumbs'];
  sidebar?: Sidebar | string;
  id: string;
};

// TODO: search results page doesn't has sidebar - add hasSidebar property? OR columns = 1 property by default = 2
// TODO: article page has three columns layout with smaller right sidebar - add hasThreeColumns property? OR columns = 3 property by default = 2
// TODO: article page has top full-width banner - add renderTopContent property?
// TODO: article page has bottom full-width 'Read also' block - add renderBottomContent property?
export const NewsWebsitePageLayout: React.FC<
  TNewsWebsitePageLayoutProps
> = props => {
  const {
    draft,
    locale,
    children,
    meta: metaProps,
    breadcrumbs,
    id,
    sidebar,
  } = props;

  const { data: headerDefaultObject } = useGetHeaderQuery({ locale });
  const { data: footerDefaultObject } = useGetFooterQuery({ locale });
  const { data: localesObject } = useGetLocalesQuery({ locale, draft });
  const { data: meta } = useGetMetaQuery({ locale });

  const locales = localesObject?.docs;
  const header = headerDefaultObject?.docs?.[0];
  const footer = footerDefaultObject?.docs?.[0];

  return (
    <div className='mx-auto max-w-[1472px] px-4'>
      {!!meta && <Meta {...meta} {...metaProps} />}

      {!!header && (
        <HeaderParser pageId={id} headers={header} locales={locales} />
      )}

      <main className='mx-auto max-w-[1256px]'>
        {!!breadcrumbs?.length && (
          <Breadcrumbs
            items={breadcrumbs}
            locale={locales?.length ? locale : ''}
            containerProps={{ isPadding: false }}
          />
        )}
        <div className='grid xl:grid-cols-[auto_296px] xl:gap-10 2xl:grid-cols-[auto_376px] 2xl:gap-12'>
          <div>{children}</div>
          <div>
            {!!sidebar && typeof sidebar !== 'string' && (
              <SidebarConnected sidebar={sidebar} />
            )}
          </div>
        </div>
      </main>

      {footer && <FooterParser footers={footer} locales={locales} />}
    </div>
  );
};
