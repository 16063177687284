import { MutableRefObject, useEffect, useRef, useState } from 'react';

type TUseSequentialAnimationsProps = {
  count?: number;
  firstDelay?: number;
  delay?: number;
  startClasses: string;
  finishClasses: string;
  reset?: boolean;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
};

type TUseSequentialAnimationsResult = {
  ref: MutableRefObject<HTMLDivElement | null>;
  transitionClasses: string[];
};

type TTimer = ReturnType<typeof setTimeout>;

export const useSequentialAnimations = ({
  count = 1,
  firstDelay = 0,
  delay = 200,
  startClasses,
  finishClasses,
  reset,
  rootMargin,
  threshold = 0,
  enabled = true,
}: TUseSequentialAnimationsProps): TUseSequentialAnimationsResult => {
  const timeoutsRef = useRef<(TTimer | null)[]>(Array(count).fill(null));
  const [transitionClasses, setTransitionClasses] = useState(() =>
    Array(count).fill(startClasses),
  );

  const ref = useRef<HTMLDivElement | null>(null);

  const isInProgress = (): boolean =>
    timeoutsRef.current.some(timeout => timeout !== null);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const startAnimation = (): void => {
      for (let i = 0; i < count; i++) {
        timeoutsRef.current[i] = setTimeout(
          () => {
            timeoutsRef.current[i] = null;
            setTransitionClasses(oldClasses => {
              const newClasses = [...oldClasses];
              newClasses[i] = finishClasses;
              return newClasses;
            });
          },
          delay * i + firstDelay,
        );
      }
    };

    const resetAnimation = (): void => {
      setTransitionClasses(Array(count).fill(startClasses));
    };

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const isAboveViewport = entry.boundingClientRect.bottom <= 0;

          if (entry.isIntersecting || isAboveViewport) {
            if (!isInProgress()) {
              startAnimation();
            }
          } else {
            if (reset) {
              resetAnimation();
            }
          }
        });
      },
      { threshold, rootMargin },
    );

    const { current } = ref;

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }

      timeoutsRef.current.forEach((timeout, i) => {
        if (timeout) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          timeoutsRef.current[i] = null;
          clearTimeout(timeout);
        }
      });
    };
  }, [
    count,
    delay,
    enabled,
    finishClasses,
    firstDelay,
    reset,
    rootMargin,
    startClasses,
    threshold,
  ]);

  return { ref, transitionClasses };
};
