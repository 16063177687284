import React from 'react';

import { ReactComponent as IconCheck } from '../../../../public/icons/icon-check-in-dropdown.svg';

export type TCardBulletsProps = {
  bulletStroke?: string;
  items?: (React.JSX.Element | string)[];
};

const CardBullets: React.FC<TCardBulletsProps> = props => {
  const { items } = props;

  if (!items?.length) {
    return null;
  }

  return (
    <ul className='flex flex-col gap-2 pt-4 text-sm leading-loose text-interface-800'>
      {items.map((item, i) => (
        <li key={i} className='flex flex-row gap-3 font-normal'>
          <div>
            <IconCheck className='inline-block aspect-square h-3 w-3 align-middle text-brand-500' />
          </div>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default CardBullets;
