import { BlogTag } from '@shared/master-types';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { PaginatedDocs } from 'payload/dist/mongoose/types';
import { Where } from 'payload/types';

import { baseApi } from '../../store/store.constants';

export const blogPostsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBlogTags: build.query<
      PaginatedDocs<BlogTag>,
      {
        locale: string;
        depth?: number;
        page?: number;
        draft?: boolean;
        limit?: number;
        where?: Where;
      }
    >({
      query: params => ({
        url: '/blog-tags',
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getBlogTags },
  useGetBlogTagsQuery,
} = blogPostsApi;
