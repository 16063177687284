import clsx from 'clsx';
import React from 'react';

import { RichText, TRichTextContent } from '../RichText';

export type TBoldTextProps = {
  boldText?: TRichTextContent;
  isOpenInNewTab?: boolean;
};

export const BoldText: React.FC<TBoldTextProps> = props => {
  const { boldText, isOpenInNewTab } = props;

  return (
    <div
      className={clsx(
        'break-words',
        'prose-a:text-brand-500 prose-a:transition hover:prose-a:text-brand-550',
        'prose-p:py-4 prose-p:text-lg prose-p:font-semibold prose-p:italic prose-p:leading-extra-loose prose-p:text-interface-1000',
      )}
    >
      <RichText isOpenInNewTab={isOpenInNewTab} content={boldText} />
    </div>
  );
};
