import { getGaId } from '@front/shared';
import {
  Container,
  EContainerVariant,
  EHeroSectionContentWidth,
  EHeroSectionTitleTheme,
  FixedElement,
  HeroSectionTitle,
  StatsV3 as Stats,
  TAction,
} from '@front/shared/ds';
import { Hero, Locale } from '@shared/master-types';
import React from 'react';

import HeroVariantVideoV2Background from './HeroVariantVideoV2Background';
import HeroVideo from './HeroVideo';

export type THeroConnectedProps = {
  hero: Hero;
  locales?: Locale[];
  fixedAction?: TAction[];
  eyebrowsAbove?: string[];
  eyebrowsBelow?: string[];
  heroActions?: TAction[];
  contentWidth?: EHeroSectionContentWidth;
};

const HeroVariantVideoV2: React.FC<THeroConnectedProps> = ({
  hero,
  fixedAction,
  eyebrowsAbove,
  eyebrowsBelow,
  heroActions,
  contentWidth = EHeroSectionContentWidth.Lg,
}) => {
  const { name, title, subtitle, stats, video, videoPoster, videoOverlay } =
    hero;

  return (
    <div className='overflow-hidden pb-12 2xl:pb-16'>
      <Container
        variant={EContainerVariant.Lead}
        dataGaId={getGaId(name || title, 'hero')}
        isNoPaddingOnMobile
      >
        <section className='flex flex-col gap-4 xl:px-4 2xl:px-0'>
          <div className='relative overflow-hidden'>
            {fixedAction?.map((action, index) => (
              <FixedElement key={index} action={action} />
            ))}
            <HeroVariantVideoV2Background />
            <HeroSectionTitle
              title={title}
              eyebrowsAbove={eyebrowsAbove}
              eyebrowsBelow={eyebrowsBelow}
              theme={EHeroSectionTitleTheme.Theme4}
              description={subtitle}
              actions={heroActions}
              contentWidth={contentWidth}
              className='sm:mt-[4rem] sm:px-4 lg:mt-[4rem] lg:gap-12 xl:mt-[5rem]'
              actionsClassName='!w-auto'
            />

            {!!video && (
              <HeroVideo
                video={video}
                videoPoster={videoPoster}
                videoOverlay={videoOverlay}
              />
            )}
          </div>
          {!!stats && (
            <div className='mt-8'>
              <Stats stats={stats} />
            </div>
          )}
        </section>
      </Container>
    </div>
  );
};
export default HeroVariantVideoV2;
