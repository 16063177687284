import { Article } from '@front/shared/ds';
import { Document } from '@shared/master-types';
import React from 'react';

export type TDocumentConnectedProps = Document;

const DocumentConnected: React.FC<TDocumentConnectedProps> = props => {
  return <Article {...props} updatedAt={undefined} />;
};

export default DocumentConnected;
