import { getGaId } from '@front/shared';
import clsx from 'clsx';
import React from 'react';

import { EActionType, TActionWithText } from '../../../../types/actions';
import { Button, EButtonVariant } from '../../../atoms/Button';

type TButtonsProps = {
  actions?: TActionWithText[];
  alignCenter?: boolean;
  className: string;
};
const ButtonGroup: React.FC<TButtonsProps> = props => {
  const { actions, className, alignCenter = false } = props;

  if (!actions?.length) {
    return null;
  }

  return (
    <div className={clsx('flex flex-wrap items-center', className)}>
      {actions.map((action, index) => {
        if (
          action.type === EActionType.ButtonType ||
          action.type === EActionType.LinkType
        ) {
          return (
            <Button
              key={index}
              data-ga-id={getGaId(action.dataGaId, 'action')}
              className={clsx(
                'w-full',
                action.props.variant !== EButtonVariant.Text && alignCenter
                  ? 'xl:w-auto'
                  : 'md:w-auto',
              )}
              {...action.props}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default ButtonGroup;
