import clsx from 'clsx';
import React from 'react';

import ButtonGroup from './ButtonGroup';
import { TActionWithText } from '../../../../types/actions';
import { EButtonVariant } from '../../../atoms/Button';

type TButtonsProps = {
  actions: TActionWithText[];
  className?: string;
  alignCenter?: boolean;
};
const Buttons: React.FC<TButtonsProps> = props => {
  const { actions, className, alignCenter = false } = props;

  if (!actions?.length) {
    return null;
  }
  const textIndex = actions.findIndex(
    action =>
      'props' in action && action.props?.variant === EButtonVariant.Text,
  );
  const mainButtons = textIndex !== -1 ? actions.slice(0, textIndex) : actions;
  const textButtons = textIndex !== -1 ? actions.slice(textIndex) : [];
  return (
    <div className={clsx(className, alignCenter && 'justify-center')}>
      <ButtonGroup
        actions={mainButtons}
        className={clsx(
          'basis-full gap-4',
          alignCenter ? 'justify-center xl:basis-auto' : 'md:basis-auto',
        )}
        alignCenter={alignCenter}
      />
      <ButtonGroup
        actions={textButtons}
        className={clsx('gap-8', alignCenter && 'justify-center')}
        alignCenter={alignCenter}
      />
    </div>
  );
};

export default Buttons;
