import { Media } from '@shared/master-types';
import { ImageProps } from 'next/image';

const toImageProps = (
  payload: string | Media | undefined,
  {
    useFill = false,
    quality = 75,
    mobileVW = '100vw',
    tabletVW = '70vw',
    desktopVW = '33vw',
  } = {},
): ImageProps | null => {
  if (!payload) {
    return null;
  }

  if (typeof payload === 'string') {
    return {
      src: payload,
      alt: '',
    };
  }

  const basicProps: ImageProps = {
    src: payload.url || '',
    alt: payload.alt,
  };

  if (useFill) {
    basicProps.sizes = `(max-width: 768px) ${mobileVW}, (max-width: 1024px) ${tabletVW}, ${desktopVW}`;
    basicProps.fill = true;
    basicProps.quality = quality;
  } else {
    basicProps.width = payload.width;
    basicProps.height = payload.height;
  }

  return basicProps;
};

export default toImageProps;
