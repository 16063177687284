import { CompanyRanking } from '@shared/master-types';

import { baseApi } from '../../store/store.constants';

export const blogPostsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBlogCompanyRanking: build.query<
      CompanyRanking,
      {
        id: string;
        depth?: number;
        locale?: string;
        draft?: boolean;
      }
    >({
      query: ({ id, ...params }) => ({
        url: `/company-rankings/${id}`,
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getBlogCompanyRanking },
  useGetBlogCompanyRankingQuery,
} = blogPostsApi;
